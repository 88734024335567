<template>
  <v-app style="background: rgb(185, 232, 237)">
    <v-card width="500" class="rounded-xl mx-auto mt-16" style="top: 15%">
      <v-img
        class="mx-auto"
        src="@/assets/LoginLogo.png"
        style="
          max-width: 120px;
          max-height: 120px;
          position: absolute;
          left: 39%;
          bottom: 85%;
        "
      ></v-img>

      <v-card-text class="mx-auto mt-6">
        <v-form style="margin-top: 20%">
          <v-text-field
            label="Email"
            prepend-icon="mdi-account-circle"
            v-model="email"
          />
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            label="Mot de passe"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-model="password"
          />
          <!--  
          <v-layout justify-space-between>
            <v-checkbox
              v-model="checkbox"
              label="Remember me"
              type="checkbox"
            ></v-checkbox>
            <a style="margin: 25px" href="">Forgot Password</a>
          </v-layout>
          -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="#00bbc0"
          style="margin-bottom: 5%"
          class="rounded-xl mx-auto"
          v-on:click="login"
          >Login</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import firebase from "firebase";
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
    };
  },
  methods: {
    login: function (e) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          () => {
            this.$router.push({
              name: "Dashboard",
            });
          },
          (err) => {
            alert("Oops. " + err.message);
          }
        );

      e.preventDefault();
    },
  },
};
</script>

<style scoped>
/* "scoped" attribute limit the CSS to this component only */
</style>
